import React, { Component, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import { useSelector } from 'react-redux'
import Dashboardwidget from "../components/dashboardwidget";
import Dashboardwidgetbarchart from "../components/dashboardwidgetbarchart";
import Dashboardwidgetinstallaton from "../components/dashboardwidgetinstallation";
import DashboardImpactMetrics from "../components/dashboardImpactMetrics";
import DashboardAgriProduced from "../components/dashboardagriproduced";
import DashboardRevenue from "../components/dashboardrevenue";
import DashboarNotification from "../components/dashboardnotification";
import CustomerLocations from "../components/customerLocations";
import Partner from "../components/partner";
import Auth from "../api/ApiSession";
import moment from "moment";
import CryptoJS from 'crypto-js';
import withCommonLandscape from "../hocs/withCommonLandscape";

const authCall = new Auth();
const OverView = (props) => {
  let user = "";
  const encrypted = sessionStorage.getItem("user");    
  if(encrypted!=null){
    const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.REACT_APP_SECRET_KEY).toString(CryptoJS.enc.Utf8);
    user = JSON.parse(decrypted);
  }
  const [isLoading, setIsLoading] = React.useState(true);
  const [customerList, setCustomerList] = React.useState([]);
  const [customerListCopy, setCustomerListCopy] = React.useState([]);
  const [totalCustomers, setTotalCustomers] = React.useState(0);
  const [agriproduced, setAgriproduced] = React.useState({});
  const [impactmetrics, setImpactmetrics] = React.useState({});
  const [capacityUtilization, setCapacityUtilization] = React.useState([]);
  const [revenue, setRevenue] = React.useState([]);
  const [environment, setEnvironment] = React.useState([]);
  const [installation, setInstallation] = React.useState([]);
  const [installation1, setInstallation1] = React.useState([]);
  const [RealTime, setRealTime] = React.useState([]);
  const [notification, setNotification] = React.useState({});
  const timerRef = React.useRef();
  let isClearable = true;
  const userObj = useSelector((state)=> state.user)

  const onToggle = (customerTypeCallback) => {
    props.onChange(moment().format("DD/MM/YYYY h:mm:ss a"));    
    setIsLoading(true);
    setAgriproduced({});
    sessionStorage.setItem("customerId", 0);
    sessionStorage.setItem("siteName", "");
    sessionStorage.setItem("customerType", customerTypeCallback);
    fetchDashboardData();
    fetchDashboardData1();
    fetchDashboardNotification();
    fetchCustomerDetails();
  };

  //site change function
  const selectedUserId = (customerId, siteName) => {
    props.onChange(moment().format("DD/MM/YYYY h:mm:ss a"));
    setIsLoading(true);
    sessionStorage.setItem("customerId", parseInt(customerId));
    if (siteName !== "All Sites") {      
      sessionStorage.setItem("siteName", siteName);
    } else {
      sessionStorage.setItem("siteName", "");
    }
    fetchDashboardData();
    fetchDashboardData1();
    fetchDashboardNotification();
  };

  const selectedSiteMobile = (site) => {
    if (site === null) {
      sessionStorage.setItem("siteSelectedInMobile", JSON.stringify({ value: 0, label: "All Sites" }));
      isClearable = false;
      props.onChange(moment().format("DD/MM/YYYY h:mm:ss a"));
      setIsLoading(true);
      sessionStorage.setItem("customerId", parseInt(0));
      sessionStorage.setItem("siteName", "");
      //update empty for All Sites
      const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: null, gatewayType: ""}), process.env.REACT_APP_SECRET_KEY).toString();
      sessionStorage.setItem("user", encrypted);     
    } else {
      fetchCustomerDetails();
      if(customerList){
        let selectedCustomerList = customerList.find(item => item.customerId === site.value);
        if(selectedCustomerList){
          //update selected site details
          const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: user.typeofuser, gatewayType: selectedCustomerList.deviceType}), process.env.REACT_APP_SECRET_KEY).toString();
          sessionStorage.setItem("user", encrypted);        
        }
      }
      authCall.getCustomerPersonalInfo(JSON.stringify(site.value)).then(details => {                        
        if(details.detailsInfo.length > 1)
        {
          const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: user.typeofuser, gatewayType: details.detailsInfo[1].infoData[1].PropValue}), process.env.REACT_APP_SECRET_KEY).toString();
          sessionStorage.setItem("user", encrypted);
          document.getElementById("configmobilelink").style.display = details.detailsInfo[1].infoData[1].PropValue === 'ATV320' ? "flex" : "none";
        }
    });
      sessionStorage.setItem("siteSelectedInMobile", JSON.stringify(site));  
      props.onChange(moment().format("DD/MM/YYYY h:mm:ss a"));
      setIsLoading(true);
      sessionStorage.setItem("customerId", parseInt(site.value));
      if (site.label !== "All Sites") {
        sessionStorage.setItem("siteName", site.label);
      } else {
        sessionStorage.setItem("siteName", "");
      }
    } 
  };
  const fetchDashboardData = () => {
    let customerType = sessionStorage.getItem("customerType") ? sessionStorage.getItem("customerType") : "Agro";
    var jsonBody = {
      siteName: sessionStorage.getItem("siteName"),
      customerId: parseInt(sessionStorage.getItem("customerId")),
      customerType: customerType,
    };
    authCall
      .postSettingsWidget(jsonBody)
      .then((json) => {
        setImpactmetrics(json.settingsWidget[0]);
        setInstallation(json.settingsWidget[1]);
        setInstallation1(json.settingsWidget[2]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchDashboardData1 = () => {
    let customerType = sessionStorage.getItem("customerType") ? sessionStorage.getItem("customerType") : "Agro";
    var jsonBody = {
      siteName: sessionStorage.getItem("siteName"),
      customerId: parseInt(sessionStorage.getItem("customerId")),
      customerType: customerType,
    };
    authCall
      .postCalculateWidget(jsonBody)
      .then((json) => {
        setAgriproduced(json.OverWidgetDetails[0]);
        setCapacityUtilization(json.OverWidgetDetails[1]);
        setRevenue(json.OverWidgetDetails[2]);
        setEnvironment(json.OverWidgetDetails[3]);
        setRealTime(json.OverWidgetDetails[4]);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchDashboardNotification = () => {
    if(document.getElementById("configPage") !== null)
    {      
      if(sessionStorage.getItem("siteName") === ""){
        document.getElementById("configPage").style.display = "none";
      }else{
        document.getElementById("configPage").style.display = (user.gatewayType === 'ATV320' || user.gatewayType === 'ATV320-V2') ? "flex" : "none";
      }
    }

    let customerType = sessionStorage.getItem("customerType") ? sessionStorage.getItem("customerType") : "Agro";
    var jsonBody = {
      siteName: sessionStorage.getItem("siteName"),
      customerId: parseInt(sessionStorage.getItem("customerId")),
      notificationDuration: 1,
      customerType: customerType,
    };
    authCall
      .postNotificationWidget(jsonBody)
      .then((json) => {
        setNotification(json.ResponseData);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const fetchCustomerDetails = () => {
    let customerType = sessionStorage.getItem("customerType") === null ? "Agro" : sessionStorage.getItem("customerType");
    authCall
      .getAgroCustomersList(customerType)
      .then((entries) => {      
        entries = entries.filter((item) => item.installedDate !== null);
        let typeofuser = "";
        if(userObj.user_type != "Admin"){
          typeofuser = entries[entries.length - 1].typeofUser;
        }else{
          typeofuser = "0";
        }

        if (entries.length === 0) {
          let { history } = props;
          history.push({
            pathname: "/mycustomers",
          });
        }
        setCustomerList(entries);
        setCustomerListCopy(JSON.parse(JSON.stringify(entries)));
        setTotalCustomers(entries.length);
        let _site = entries.filter(
          (e) => e.customerId === parseInt(sessionStorage.getItem("customerId"))
        );
        
        //update selected customer details
        if (_site.length) {
          sessionStorage.setItem("siteName", _site[0].location);        
          const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser: _site[0].typeofUser,gatewayType: _site[0].deviceType}), process.env.REACT_APP_SECRET_KEY).toString();
          sessionStorage.setItem("user", encrypted);

          //set show/hide configuration tab
          if(document.getElementById("configPage") !== null)
          {      
            if(sessionStorage.getItem("siteName") === ""){
              document.getElementById("configPage").style.display = "none";
            }else{
              document.getElementById("configPage").style.display = (_site[0].deviceType === 'ATV320' || _site[0].deviceType === 'ATV320-V2') ? "flex" : "none";
            }
          }
        }

        //clear data for All Sites
        if(sessionStorage.getItem("siteName") === "All Sites" || sessionStorage.getItem("siteName") === ""){
          const encrypted = CryptoJS.AES.encrypt(JSON.stringify({userid:user.userid, user_type:user.user_type, typeofuser:typeofuser,gatewayType: ""}), process.env.REACT_APP_SECRET_KEY).toString();
          sessionStorage.setItem("user", encrypted);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  React.useEffect(() => {
    props.onChange(moment().format("DD/MM/YYYY h:mm:ss a"));
    fetchDashboardData();
    fetchDashboardData1();
    fetchDashboardNotification();
    fetchCustomerDetails();
  }, []);
  React.useEffect(() => {
    timerRef.current = setInterval(() => {
      props.onChange(moment().format("DD/MM/YYYY h:mm:ss a"));
      fetchDashboardData();
      fetchDashboardData1();
      fetchDashboardNotification();
      fetchCustomerDetails();
    }, 1800000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, []);

  let optionList = customerList.map((item) => {
    // debugger
    return { value: item.customerId, label: item.Customers + ' - ' + item.state };
  });
  optionList.splice(0, 1, { value: 0, label: "All Sites" });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#3DCD58" : "white",
      color: state.isSelected ? "white" : "black",
      fontFamily: "Nunito"
    }),
  };
  useEffect(() => {
    if (sessionStorage.getItem("siteSelectedInMobile")) {
      return;
    } else {
      sessionStorage.setItem("siteSelectedInMobile",JSON.stringify({ value: 0, label: "All Sites" }));
      isClearable = false;
    }
  }, []);
  if (sessionStorage.getItem("siteSelectedInMobile") && JSON.parse(sessionStorage.getItem("siteSelectedInMobile")).value == 0) {
    isClearable = false;
  }
  const isMobile = window.innerWidth <= 600;
  const isTab = (window.innerWidth >=750 && window.innerWidth < 1024);  
  const portrait = window.matchMedia("(orientation: portrait)").matches;  
  
  return (
    <>
      <se-container class="mobilecontainer" display="flex" padding="medium">
        {isLoading ? (
          <div class="align-items-center">
            <se-loading loading="true"></se-loading>
          </div>
        ) : null}
        <se-block margin="medium" class="hideinMobile col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden" color="standard">
          <CustomerLocations customerList={customerList} totalCustomers={totalCustomers} onChange={onToggle} onClick={selectedUserId} />
          <DashboarNotification className="pt-3"  notification={notification} />
        </se-block>
        {((user.typeofuser === "1" && (isTab === false && portrait === false)) || isMobile || window.innerWidth <750) && (
          <div class="col-xl-9 col-md-12 col-sm-3 row mobilewidgetwidth" style={{paddingRight: '0px'}}>
            <div className="search-mobile" style={{display:"none"}}>
              <Select
                styles={customStyles}
                defaultMenuIsOpen={false}
                isClearable={isClearable}
                options={optionList}
                placeholder="Select site"
                onChange={selectedSiteMobile}
                value={JSON.parse(sessionStorage.getItem("siteSelectedInMobile"))}
              />
            </div>

            {((user.typeofuser === "0") &&
              <>
              <div class="col-sm-4" style={{paddingLeft:'10px',paddingRight: '0px'}}>              
              <DashboardImpactMetrics impactmetrics={impactmetrics} customerType={sessionStorage.getItem("customerType")} />
              </div>
              </>
            )}

            <div class="col-sm-4 waterutilization-mobile-widget" style={{paddingLeft:'10px',paddingRight:'0px'}}>
              <div>
                <DashboardAgriProduced agriproduced={agriproduced} />
              </div>
            </div>
            <div class="col-sm-4" style={{paddingLeft:'10px',paddingRight: '0px'}}>
              <div>
                  <Partner realTime={RealTime} />
              </div>
            </div>
            <div class="col-sm-4" style={{paddingLeft:'10px',paddingRight: '0px'}}>
              <div>
                  <Dashboardwidget environment={environment} />
              </div>
            </div>
            <div class="col-sm-4" style={{paddingLeft:'10px',paddingRight: '0px'}}>
              <div class="card-body card-padding" style={{padding:'0px'}}>
                <Dashboardwidgetinstallaton installation={installation} installation1={installation1} />
              </div>
            </div>
            <div class="col-sm-4" style={{paddingLeft:'10px',paddingRight: '0px'}}>
              <div>
                <div class="padding-bottom">    
                  <Dashboardwidgetbarchart capacityUtilization={capacityUtilization} /> 
                </div>
              </div>
            </div>

            {((user.typeofuser === "0") &&              
              <DashboardRevenue revenue={revenue} />
            )}
          </div>
        )}
        {((user.typeofuser === "1" || user.typeofuser === "0") && (window.innerWidth >=750 && window.innerWidth < 1024)) && (          
          <div class="col-xl-9 col-md-6 row mobilewidgetwidth ipadheight">            
            <div class="row">
            {((user.typeofuser === "0") &&                
              <DashboardImpactMetrics impactmetrics={impactmetrics} customerType={sessionStorage.getItem("customerType")} />
            )}
              <DashboardAgriProduced agriproduced={agriproduced} />
              <Partner realTime={RealTime} />
              <Dashboardwidget environment={environment} />
              <Dashboardwidgetinstallaton installation={installation} installation1={installation1} />
              <Dashboardwidgetbarchart capacityUtilization={capacityUtilization} /> 
              {((user.typeofuser === "0") &&
                <DashboardRevenue revenue={revenue} />
              )}
            </div>            
          </div>
        )}
        {((user.typeofuser === "0" && (isTab === false && portrait === false)) || isMobile || window.innerWidth <750) && (
          <>
            <se-block
              margin="medium"
              class="hideinMobile col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden"
              color="standard"
            >
              <DashboardImpactMetrics
                impactmetrics={impactmetrics}
                customerType={sessionStorage.getItem("customerType")}
              />
              <DashboardAgriProduced agriproduced={agriproduced} />
            </se-block>
            <se-block
              margin="medium"
              class="hideinMobile col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden"
              color="standard"
            >
              <se-block
                margin="none"
                class="hideinMobile font-icon overflow-hidden"
                style={{ height: "54vh", borderBottom: "1px solid #00000029" }}
              >
                <Dashboardwidgetbarchart
                  capacityUtilization={capacityUtilization}
                />
                <DashboardRevenue revenue={revenue} />
              </se-block>
              <Dashboardwidget environment={environment} />
            </se-block>
            <se-block
              margin="medium"
              class="hideinMobile col-md-6 col-lg-4 col-xl-3 px-0 overview-page overflow-hidden"
              color="standard"
            >
              <Dashboardwidgetinstallaton
                installation={installation}
                installation1={installation1}
              />
              <Partner realTime={RealTime} />
            </se-block>
          </>
        )}
      </se-container>
    </>
  );
};

export default withCommonLandscape(withRouter(OverView));
